<!-- eslint-disable -->
<template>
  <div>
    <slot name="title">
      <VTitle :title="$t('app.summary')" class="overview-title" />
    </slot>

    <VCard>
      <template #content>
        <div class="flex">
          <div class="px-8 py-12 flex flex-col justify-center flex-1">
            <div class="text-3xl font-medium mb-8">
              {{ summary.total }}

              <span class="lowercase">
                {{ $t("app.items_require_attention_msg", summary.total) }}
              </span>
            </div>

            <VChip
              :text="`${summary.past_due} ${$t('app.past_due')}`"
              class="red rank w-40 mb-2"
            />

            <VChip
              :text="`${summary.due_soon} ${$t('app.due_soon')}`"
              class="yellow rank w-40 mb-8"
            />

            <div class="text-gray-600 dark:text-gray-600">
              {{ $t("app.items_requiring_attention_msg") }}
            </div>

            <div class="flex-1" />
          </div>

          <div
            class="px-8 py-12 flex flex-col justify-center flex-1 border-t sm:border-t-0 sm:border-l border-gray-300 dark:border-dark-5 border-dashed"
          >
            <template
              v-for="(value, key, index) in summary.breakdown"
              :key="index"
            >
              <div
                class="uppercase text-gray-600 dark:text-gray-600 text-xs"
                :class="{ 'mt-2': index !== 0 }"
              >
                {{ $t(`backend.${key}`) }}
              </div>

              <div class="flex">
                <div
                  v-for="(value2, key2, index2) in value"
                  :key="`${key}-${index2}`"
                  class="mt-1.5 flex items-center gap-1"

                  :class="[
                    { 'mr-4': value2 !== 0 },
                    { 'text-red-500': key2 === 'past_due' },
                    { 'text-yellow-500': key2 === 'approaching_deadline' },
                    { 'text-yellow-500': key2 === 'pending_approval' },
                  ]"
                >
                  <div class="capitalize" :class="{ 'hidden': key2 !== 'total' && value2 === 0 }">
                    {{ $t(`backend.${key2}`) }}
                  </div>

                  <div :class="{ 'hidden': key2 !== 'total' && value2 === 0 }">
                    {{ value2 }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
// Components
import VChip from "@/components/VChip";
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VChip,
    VCard,
    VTitle
  },
  props: {
    summary: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
