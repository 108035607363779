<template>
  <div>
    <VTitle
      :title="$t('app.scheduled_observations', 2)"
      class="overview-title"
    />

    <VCalendar :markers="markers">
      <template v-if="$slots.default" #default>
        <slot />
      </template>
    </VCalendar>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
// Components
import VCalendar from "@/components/VCalendar";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VCalendar,
    VTitle
  },
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Composables
    const { getText } = useTexts();

    // Computed
    const markers = computed(() => {
      return props.events.map(event => ({
        ...event,
        date: event?.observation_scheduled_on,
        name: getText(event?.evaluation?.texts, "name")
      }));
    });

    return {
      markers
    };
  }
};
</script>
