<template>
  <div :class="{ 'intro-y': hasAnimation }">
    <div class="flex justify-between items-center">
      <slot>
        <VTitle
          :title="$t('app.registrations_to_complete')"
          class="overview-title"
        />
      </slot>

      <div
        class="boxed-tabs nav nav-tabs justify-end bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md"
        role="tablist"
      >
        <VTab
          :name="TABS.COLLECTIONS"
          :selected-tab="currentTab"
          :label="$t('app.collections', 2)"
          class="btn border-0 shadow-none py-1.5 px-10"
          lowercase-label
          @click="updateTab"
        />

        <VTab
          :name="TABS.TRAININGS"
          :selected-tab="currentTab"
          :label="$t('app.trainings', 2)"
          class="btn border-0 shadow-none py-1.5 px-10 end-tab"
          lowercase-label
          @click="updateTab"
        />
      </div>
    </div>

    <VList
      :rows="displayedRows"
      :headers="headers"
      hide-header
      hide-headers
      @click:row="onClickRedirectToRegistration($event)"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :color="item.color" :name="item.name" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        <div>
          <div class="font-medium whitespace-nowrap">
            {{ item.name }}
          </div>

          <div class="version mt-0.5">
            {{ $t("app.versions") }} {{ item.version }}
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="item.displayed_status"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useTexts from "@/composables/useTexts";
import useAnimation from "@/composables/useAnimation";
import useColor from "@/composables/useColor";
import useTabs from "@/composables/useTabs";
// Components
import VTab from "@/components/VTab";
import VTitle from "@/components/VTitle";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VList from "@/components/tables/VList";

export default {
  components: {
    VTab,
    VTitle,
    VList,
    VImage,
    VChip
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const TABS = {
      TRAININGS: "trainings",
      COLLECTIONS: "collections"
    };
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        value: "name"
      },
      {
        value: "status",
        class: "w-10"
      }
    ];

    // Composables
    const { currentTab, updateTab } = useTabs(TABS.TRAININGS);
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { hasAnimation } = useAnimation();

    // Computed
    const trainings = computed(() => {
      return props.data?.trainings?.map(registration => {
        return formatData(registration, "training");
      });
    });
    const collections = computed(() => {
      return props.data?.collections?.map(registration => {
        return formatData(registration, "collection");
      });
    });
    const displayedRows = computed(() => {
      return currentTab.value === TABS.TRAININGS
        ? trainings.value
        : collections.value;
    });

    // Methods
    const formatData = (registration, type) => ({
      ...registration,
      clickable: type === "training",
      rowClass: type === "training" ? "cursor-pointer" : "",
      name: getText(registration[type]?.texts, "name"),
      color: registration[type]?.colour,
      version: registration[type]?.version,
      displayed_status: t(`app.${registration.status}`)
    });

    const onClickRedirectToRegistration = item => {
      if (!item?.clickable) {
        return;
      }

      router.push({
        name: "learning-registrations-details",
        params: { id: item?.id }
      });
    };

    return {
      TABS,
      headers,
      displayedRows,
      onClickRedirectToRegistration,
      // useAnimation
      hasAnimation,
      // useTabs
      currentTab,
      updateTab,
      // useColor
      getStatusColor
    };
  }
};
</script>
