<template>
  <LearningScoreProgression
    :competencies="competencies"
    :completions="completions"
  >
    <template #score="{ completionScore, competencyScore }">
      <div class="uppercase mb-4">
        {{ $t("app.current_score") }}
      </div>

      <div class="grid grid-cols-2 gap-4">
        <div class="flex border-black border-opacity-10 border-r border-dashed">
          <VScore v-if="completionScore" :score="completionScore" />

          <div class="uppercase text-xs text-left w-24 ml-2 label">
            {{ $t("app.completion_score") }}
          </div>

          <VHint class="text-base">
            {{ $t("hints.teaching_learners_completion_score") }}
          </VHint>
        </div>

        <div class="flex">
          <VScore v-if="competencyScore" :score="competencyScore" />

          <div class="uppercase text-xs text-left w-24 ml-2 label">
            {{ $t("app.competency_score") }}
          </div>

          <VHint class="text-base">
            {{ $t("hints.teaching_learners_competency_score") }}
          </VHint>
        </div>
      </div>

      <hr class="border-black border-opacity-10 border-t border-dashed my-8" />

      <div class="uppercase mb-4">
        {{ $t("app.scores_progression") }}
      </div>
    </template>
  </LearningScoreProgression>
</template>

<script>
import { computed } from "vue";
// Components
import LearningScoreProgression from "@/views/teaching/learners/overview/LearningScoreProgression";
import VScore from "@/components/VScore";
import VHint from "@/components/VHint";

export default {
  components: {
    LearningScoreProgression,
    VScore,
    VHint
  },
  props: {
    learningScores: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Computed
    const competencies = computed(() => props.learningScores?.competencies);
    const completions = computed(() => props.learningScores?.completions);

    return {
      competencies,
      completions
    };
  }
};
</script>
